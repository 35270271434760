import { ethers, utils } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';

const { getAddress } = utils;
const ContractAbi = require('artifacts/contracts/ERC721Token.sol/ERC721Token.json').abi;

export const getMetamaskSigner = async () => {
  const browserProvider = await detectEthereumProvider();
  if (browserProvider?.isMetaMask !== true) {
    return {
      signer: null,
      wallet_address: null
    };
  }

  const provider = new ethers.providers.Web3Provider(browserProvider);
  const walletAccounts = await provider.listAccounts() || [];
  const walletNetwork = await provider.getNetwork() || {};

  return {
    signer: provider?.getSigner(),
    wallet_address: walletAccounts[0],
    network: walletNetwork
  };
}

export const connectMetamask = async (_options = {}) => {
  try {
    const { signingMessage = 'Please install Metamask' } = _options || {};
    const browserProvider = await detectEthereumProvider();
    if (browserProvider?.isMetaMask !== true) {
      return {
        success: false,
        data: {
          message: signingMessage
        }
      };
    }

    let walletAccounts;
    let walletNetwork;
    const provider = new ethers.providers.Web3Provider(browserProvider);
    walletAccounts = await provider.listAccounts();
    walletNetwork = await provider.getNetwork() || {};
    if (walletAccounts.length === 0 && provider && provider.provider) {
      await provider.provider.request({ method: 'eth_requestAccounts' });
      walletAccounts = await provider.listAccounts();
      walletNetwork = await provider.getNetwork() || {};
      return {
        signer: provider.getSigner(),
        wallet_address: walletAccounts[0],
        network: walletNetwork
      };
    } else {
      return {
        signer: provider.getSigner(),
        wallet_address: walletAccounts[0],
        network: walletNetwork
      };
    }
  } catch (error) {
    return {
      signer: null,
      wallet_address: null,
      network: {}
    };
  }
}

export const checkTokenApproval = async ({ signer, token_address, wallet_address }) => {
  const contract = new ethers.Contract(
    token_address,
    ContractAbi,
    signer
  );

  const isApprovalForAll = await contract.isApprovedForAll(wallet_address, process.env.REACT_APP_STAKING_CONTRACT);

  if (!isApprovalForAll) {
    let responseApproval = await contract.setApprovalForAll(process.env.REACT_APP_STAKING_CONTRACT, true);
    await responseApproval.wait();
  }
}

export const convertNFTImage = (tokenAddress, tokenId, image) => {
  switch (getAddress(tokenAddress)) {
    case getAddress(process.env.REACT_APP_ANTHRO_CONTRACT):
      return `https://ipfs.moralis.io:2053/ipfs/Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U/${tokenId}.png`;
    case getAddress(process.env.REACT_APP_HUMAN_CONTRACT):
      return `https://ipfs.moralis.io:2053/ipfs/QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU/${tokenId}.png`;
    case getAddress(process.env.REACT_APP_HUMANMUSIC_CONTRACT):
      return `https://ipfs.moralis.io:2053/ipfs/QmW36JX1Tpyyxwy2fTSHkJmzJZw9ZTC6oA4FF4kvScQ7cV/${tokenId}.mp4`;
    default:
      return `https://gateway.ipfs.io/${image.replace('ipfs://', 'ipfs/')}`;
  }
}
