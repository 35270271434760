import { useEffect, useState } from "react"
import { connectMetamask, getMetamaskSigner } from "utils/web3.utils";

const ethereum = window['ethereum'];

export const useWallet = () => {
  const [address, setAddress] = useState();
  const [signer, setSigner] = useState();
  const [network, setNetwork] = useState({});
  const [isLoading, setLoading] = useState(false);

  const fetchMetamaskSigner = async () => {
    setLoading(true);
    const responseMetamask = await getMetamaskSigner();

    const { signer: metamaskSigner, wallet_address, network: chainInfo } = responseMetamask;

    setAddress(wallet_address);
    setSigner(metamaskSigner);
    setNetwork(chainInfo);
    setLoading(false);
  }

  const connectWallet = async () => {
    setLoading(true);
    const responseMetamask = await connectMetamask();
    if (!responseMetamask.success) {
      return responseMetamask;
    }

    const { signer: metamaskSigner, wallet_address, network: chainInfo } = responseMetamask;

    setAddress(wallet_address);
    setSigner(metamaskSigner);
    setNetwork(chainInfo);
    setLoading(false);
  }
  const onAccountChange = (accounts) => {
    const accountSwitch = accounts[0];

    if (accountSwitch) {
      setAddress(accountSwitch)
    } else {
      setAddress(null);
      setSigner(null);
    }
  }
  const onChainChange = () => {
    window.location.reload();
  }
  const switchNetwork = chainId => {
    ethereum?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: chainId }] });
  }

  useEffect(() => {
    fetchMetamaskSigner();
    ethereum?.on('accountsChanged', onAccountChange);
    ethereum?.on('chainChanged', onChainChange);
  }, []);

  return { signer, address, network, connectWallet, isLoading, switchNetwork };
}

