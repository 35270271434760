import { ethers, utils } from "ethers";
import { useMemo } from "react";
import { useWallet } from "./useWallet";
import axios from 'axios';
import { useQuery } from "react-query";

const ContractAbi = require('artifacts/contracts/ERC20Token.sol/ERC20Token.json').abi;
const { hexValue } = utils;

export const useERC20 = (contractAddress) => {
  const { signer, address, network: { chainId } } = useWallet();

  const contract = useMemo(() => {
    if (contractAddress && signer) {
      const nftContract = new ethers.Contract(
        contractAddress,
        ContractAbi,
        signer
      );
      return nftContract;
    }
  }, [contractAddress, signer]);

  const fetchBalance = async (params) => {
    const { queryKey } = params;
    const wallet_address = queryKey[1];
    const chain = queryKey[2];
    if (wallet_address && chain) {
      try {
        const options = {
          params: {
            chain: hexValue(chain), token_addresses: [
              contractAddress
            ]
          },
          headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY }
        };

        const res = await axios.get(`https://deep-index.moralis.io/api/v2/${wallet_address}/erc20`, options);
        if (res?.data && res?.data.length > 0) {
          return res.data[0];
        } else {
          return {};
        }
      } catch (error) {
        console.log('error: ', error);
        return {};
      }
    } else {
      return {};
    }
  }

  const useGetBalance = useQuery(['fetch-erc20', address, chainId], fetchBalance);

  return { contract, useGetBalance };
}

