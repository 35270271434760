import { ethers, utils } from "ethers";
import { useMemo } from "react";
import { useWallet } from "./useWallet";
import axios from 'axios';
import { useQuery } from "react-query";

const ContractAbi = require('artifacts/contracts/ERC721Token.sol/ERC721Token.json').abi;
const { hexValue } = utils;

export const useNFT = (contractAddress) => {
  const { signer, address, network } = useWallet();
  const { chainId } = network;

  const contract = useMemo(() => {
    if (contractAddress && signer) {
      const nftContract = new ethers.Contract(
        contractAddress,
        ContractAbi,
        signer
      );
      return nftContract;
    }
  }, [contractAddress, signer]);

  const isApprovedStaking = async () => {
    if (!contract) return;
    return await contract.isApprovedForAll(address, process.env.REACT_APP_STAKING_CONTRACT);
  }

  const setApprovedStaking = async () => {
    if (!contract) return;
    let responseApproval = await contract.setApprovalForAll(process.env.REACT_APP_STAKING_CONTRACT, true);
    await responseApproval.wait();
  }

  const fetchNFTs = async (params) => {
    const { queryKey } = params;
    const wallet_address = queryKey[1];
    const chain = queryKey[2];
    if (wallet_address && chain) {
      try {
        const options = {
          params: { 
            limit: 100,
            chain: hexValue(chain), 
            format: 'decimal', 
            token_addresses: [contractAddress] 
          },
          headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY }
        };

        const res = await axios.get(`https://deep-index.moralis.io/api/v2/${wallet_address}/nft`, options);
        if (res?.data?.result) {
          return res.data.result.map(item => {
            const metadata = JSON.parse(item.metadata);
            return {
              ...item,
              metadata,
              image: `https://gateway.ipfs.io/${metadata?.image.replace('ipfs://', 'ipfs/')}`
            }
          });
        } else {
          return [];
        }
      } catch (error) {
        console.log('error: ', error);
        return [];
      }
    } else {
      return [];
    }
  }

  const useGetNFTs = useQuery(['fetch-nfts', address, chainId], fetchNFTs);

  return { contract, isApprovedStaking, setApprovedStaking, useGetNFTs };
}

