import { ethers } from "ethers";
import { useMemo } from "react";
import { useWallet } from "./useWallet";

const ContractAbi = require('artifacts/contracts/NFTStaking.sol/NFTStaking.json').abi;

export const useStaking = () => {
  const { signer } = useWallet();

  const contract = useMemo(() => {
    if (signer) {
      const nftContract = new ethers.Contract(
        process.env.REACT_APP_STAKING_CONTRACT,
        ContractAbi,
        signer
      );
      return nftContract;
    }
  }, [signer]);

  return contract;
}

