
import { Button, Checkbox, Image, Skeleton, message } from "antd";
import { utils as ethersUtils } from "ethers";
import { useNFT } from "hooks/useNFT";
import { useStaking } from "hooks/useStaking";
import { useEffect, useState } from "react";
import styles from "styles/home.module.css";
import { s0uVaults, s0uImageFallback, s0uNFTs, ANTHROAddress, HUMANAddress, HUMANMUSICAddress } from "utils/constant";
import { convertNFTImage } from "utils/web3.utils";
import { useERC20 } from "hooks/useERC20";
import axios from "axios";
import { useWallet } from "hooks/useWallet";

const { formatEther, getAddress, hexValue } = ethersUtils;
const HomePage = () => {
  const [isLoading, setLoading] = useState(false);
  const [isFetchNFTs, seIsFetchNFTs] = useState(false);
  const [isSelectStake, setIsSelectStake] = useState(false);
  const [isSelectWithdraw, setIsSelectWithdraw] = useState(false);
  const [allowWithdraw, setAllowWithdraw] = useState(false);
  // const [isModalOpen, setModalOpen] = useState(false);

  const [claimableRewards, setClaimableRewards] = useState();
  // const [nftProperties, setnftProperties] = useState({});
  const [stakedTokens, setStakedTokens] = useState({});
  const [nftBalance, setNFTBalance] = useState([]);
  const [selectedStake, setSelectedNFTs] = useState([]);
  const [selectedWithdraw, setSelectedWithdraw] = useState([]);

  const { address: account, network = {} } = useWallet();
  const chainId = hexValue(network.chainId || 1);

  const stakingContract = useStaking();

  const { isApprovedStaking: isApprovedANTHRO, setApprovedStaking: setApprovedANTHRO } = useNFT(ANTHROAddress);
  const { isApprovedStaking: isApprovedHUMAN, setApprovedStaking: setApprovedHUMAN } = useNFT(HUMANAddress);
  const { isApprovedStaking: isApprovedHUMANMUSIC, setApprovedStaking: setApprovedHUMANMUSIC } = useNFT(HUMANMUSICAddress);

  const { useGetBalance } = useERC20(process.env.REACT_APP_REWARD_TOKEN);
  const { data: tokenBalance } = useGetBalance;

  const fetchMyNFTs = async () => {
    try {
      seIsFetchNFTs(true);
      const options = {
        params: {
          limit: 100,
          chain: chainId,
          format: 'decimal',
          token_addresses: [ANTHROAddress, HUMANAddress, HUMANMUSICAddress]
        },
        headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY }
      };

      const res = await axios.get(`https://deep-index.moralis.io/api/v2/${account}/nft`, options);
      if (res?.data?.result) {
        setNFTBalance(res.data.result.map(item => {
          const metadata = JSON.parse(item.metadata);
          return {
            ...item,
            metadata
          }
        }));
      } else {
        setNFTBalance([]);
      }
      seIsFetchNFTs(false);
    } catch (error) {
      setNFTBalance([]);
      seIsFetchNFTs(false);
    }
  }

  useEffect(() => {
    if (account) {
      fetchMyNFTs();
    }
  }, [account])

  useEffect(() => {
    if (stakingContract) {
      getVaults([ANTHROAddress, HUMANAddress, HUMANMUSICAddress]);
      getUserStakeInfo();
    }
  }, [stakingContract])

  const refreshAllData = () => {
    fetchMyNFTs();
    getVaults([ANTHROAddress, HUMANAddress, HUMANMUSICAddress]);
    getUserStakeInfo();
  }

  const getVaults = async (listTokens) => {
    if (stakingContract) {
      try {
        setAllowWithdraw(false);
        let stakedItems = {};
        for (let tokenAddress of listTokens) {
          const response = await stakingContract.tokensOfOwner(getAddress(account), s0uVaults[tokenAddress]);
          if (response && response.length > 0) {
            setAllowWithdraw(true);
          }
          stakedItems = {
            ...stakedItems,
            [tokenAddress]: response.map(item => item.toNumber())
          }
        }
        setStakedTokens(stakedItems);
        return stakedItems;
      } catch (error) {
        console.log('error: ', error);
        return {};
      }
    }
  }

  const getUserStakeInfo = async () => {
    if (stakingContract && account) {
      try {
        const response = await stakingContract.userStakeInfo(getAddress(account));
        setClaimableRewards(formatEther(response._availableRewards));
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }

  const validateTokenApproval = async (params = []) => {
    for (let item of params) {
      switch (item.pid) {
        case s0uVaults.ANTHRO:
          !await isApprovedANTHRO() && await setApprovedANTHRO();
          break;
        case s0uVaults.HUMAN:
          !await isApprovedHUMAN() && await setApprovedHUMAN();
          break;
        case s0uVaults.HUMANMUSIC:
          !await isApprovedHUMANMUSIC() && await setApprovedHUMANMUSIC();
          break;
        default:
          break;
      }
    }
  }
  const startStake = async () => {
    if (isSelectStake) {
      await stakeNFTs();
      setIsSelectStake(false);
      setSelectedNFTs([]);
    } else {
      setSelectedNFTs([]);
      setIsSelectStake(true);
    }
  }
  const startWithdraw = async () => {
    if (isSelectWithdraw) {
      await withdrawNFTs();
      setIsSelectWithdraw(false);
      setSelectedWithdraw([]);
    } else {
      setSelectedWithdraw([]);
      setIsSelectWithdraw(true);
    }
  }
  const cancelWithdraw = async () => {
    setSelectedWithdraw([]);
    setIsSelectWithdraw(false);
  }
  const cancelStake = async () => {
    setSelectedNFTs([]);
    setIsSelectStake(false);
  }
  const stakeNFTs = async () => {
    if (stakingContract) {
      try {
        let vaults = {};
        for (const item of selectedStake) {
          if (vaults[item.tokenAddress]) {
            vaults[item.tokenAddress] = [
              ...vaults[item.tokenAddress],
              parseInt(item.tokenId)
            ];
          } else {
            vaults[item.tokenAddress] = [parseInt(item.tokenId)];
          }
        }
        setLoading(true);
        const params = Object.entries(vaults).map(([key, value]) => {
          return {
            pid: s0uVaults[getAddress(key)],
            tokenIds: value
          }
        });
        await validateTokenApproval(params);
        const transaction = await stakingContract.stake(params);

        await transaction.wait();
        console.log('transaction: ', transaction);
        setLoading(false);
        refreshAllData();
        message.success('Stake NFTs success');
      } catch (error) {
        console.log('error: ', error);
        message.error('Stake NFTs fail');
        setLoading(false);
      }
    } else {
      return;
    }
  }
  const withdrawNFTs = async () => {
    if (stakingContract) {
      try {
        setLoading(true);
        let vaults = {};
        for (const item of selectedWithdraw) {
          if (vaults[item.tokenAddress]) {
            vaults[item.tokenAddress] = [
              ...vaults[item.tokenAddress],
              parseInt(item.tokenId)
            ];
          } else {
            vaults[item.tokenAddress] = [parseInt(item.tokenId)];
          }
        }

        const params = Object.entries(vaults).map(([key, value]) => {
          return {
            pid: s0uVaults[getAddress(key)],
            tokenIds: value
          }
        });
        const transaction = await stakingContract.withdraw(params);
        await transaction.wait();
        setLoading(false);
        console.log('Withdraw: ', transaction);
        refreshAllData();
        message.success('Withdraw success');
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
        message.error('Withdraw fail');
      }
    } else {
      return;
    }
  }
  const claimRewardsWithdraw = async () => {
    if (stakingContract) {
      try {
        const vaults = await getVaults([ANTHROAddress, HUMANAddress, HUMANMUSICAddress]);
        const params = Object.entries(vaults).map(([key, value]) => {
          return {
            pid: s0uVaults[getAddress(key)],
            tokenIds: value
          }
        });
        setLoading(true);
        // const transaction = await stakingContract.claimRewards();
        const transaction = await stakingContract.withdrawAndClaimRewards(params);
        await transaction.wait();
        setLoading(false);
        console.log('widthdraw: ', transaction);
        refreshAllData();
        message.success('Claim and withdraw success');
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
        message.error('Claim and withdraw fail');
      }
    } else {
      return;
    }
  }
  const claimRewards = async () => {
    if (stakingContract) {
      try {
        setLoading(true);
        const transaction = await stakingContract.claimRewards();
        await transaction.wait();
        setLoading(false);
        console.log('claim rewards: ', transaction);
        refreshAllData();
        message.success('Claim rewards success');
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
        message.error('Claim rewards fail');
      }
    } else {
      return;
    }
  }

  const handleSelectNFT = (tokenAddress, tokenId) => {
    const nft = {
      tokenAddress,
      tokenId
    };
    if (isSelectStake) {
      if (isSelectedStake(nft)) {
        const rest = selectedStake.filter(item => item.tokenAddress !== tokenAddress && item.tokenId !== tokenId);
        setSelectedNFTs([...rest]);
      } else {
        setSelectedNFTs([...selectedStake, nft])
      }
    }
    // else {
    //   setnftProperties(nft)
    //   setModalOpen(true);
    // }
  }
  const handleSelectWithdraw = (tokenAddress, tokenId) => {
    if (isSelectWithdraw) {
      const nft = {
        tokenAddress,
        tokenId
      };
      if (isSelectedWithdraw(nft)) {
        const rest = selectedWithdraw.filter(item => item.tokenAddress !== tokenAddress && item.tokenId !== tokenId);
        setSelectedWithdraw([...rest]);
      } else {
        setSelectedWithdraw([...selectedWithdraw, nft])
      }
    }
  }

  const isSelectedStake = (nft) => {
    const selectedItems = selectedStake.find(item => item.tokenAddress === nft.token_address && item.tokenId === nft.token_id);
    if (selectedItems) {
      return true;
    } else {
      return false;
    }
  }
  const isSelectedWithdraw = (nft) => {
    const selectedItems = selectedWithdraw.find(item => item.tokenAddress === nft.tokenAddress && item.tokenId === nft.tokenId);
    if (selectedItems) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Stake Your NFTs</h1>
      <hr className={`${styles.divider} ${styles.spacerTop}`} />
      <h2>Your Tokens</h2>
      <div className={styles.tokenGrid}>
        <div className={styles.tokenItem}>
          <h3 className={styles.tokenLabel}>Claimable Rewards</h3>
          <p className={styles.tokenValue}>
            <b>
              {!claimableRewards
                ? "Loading..."
                : claimableRewards}
            </b>{" "}
            {tokenBalance?.symbol}
          </p>
        </div>
        <div className={styles.tokenItem}>
          <h3 className={styles.tokenLabel}>Current Balance</h3>
          <p className={styles.tokenValue}>
            <b>{formatEther(tokenBalance?.balance || 0)}</b> {tokenBalance?.symbol}
          </p>
        </div>
      </div>

      <div className={`${styles.boxCenter}`}>
        <Button
          className={`${styles.mainButton} ${styles.spacerTop} ${claimableRewards <= 0 && styles.disabled}`}
          onClick={() => claimRewards()}
        >
          Claim Rewards
        </Button>
        {
          allowWithdraw && <Button
            className={`${styles.mainButton} ${styles.spacerTop} ${claimableRewards <= 0 && styles.disabled}`}
            onClick={() => claimRewardsWithdraw()}
          >
            Claim Rewards & Withdraw
          </Button>
        }
      </div>

      <hr className={`${styles.divider} ${styles.spacerTop}`} />

      <h2>Your Staked NFTs</h2>
      <div className={styles.nftBoxGrid}>
        {Object.entries(stakedTokens)?.map(([tokenAddress, tokenIds]) => tokenIds.map(tokenId => (
          <div
            key={`${tokenAddress}-${tokenId}`}
            className={`${styles.nftBox} ${isSelectedWithdraw({ tokenAddress, tokenId }) && styles.selectedNFTWithdraw}`}
            onClick={() => handleSelectWithdraw(tokenAddress, tokenId)}
          >
            {
              s0uNFTs[getAddress(tokenAddress)] === s0uNFTs.HUMANMUSIC
                ? <video style={{ width: "100%" }} controls>
                  <source src={convertNFTImage(tokenAddress, tokenId, '') || "error"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                : <Image
                  preview={false}
                  src={convertNFTImage(tokenAddress, tokenId, '') || "error"}
                  fallback={s0uImageFallback[tokenAddress]}
                  alt={`s0u ${s0uNFTs[tokenAddress]} #${tokenId}`}
                  style={{ objectFit: "contain" }}
                />
            }
            <h4>{`s0u ${s0uNFTs[tokenAddress]} #${tokenId}`}</h4>
            {
              isSelectWithdraw && <div className={styles.checkbox}>
                <Checkbox className={`${isSelectedWithdraw({ tokenAddress, tokenId }) && styles.redCheckbox}`} checked={isSelectedWithdraw({ tokenAddress, tokenId })}></Checkbox>
              </div>
            }
          </div>
        )))}
      </div>
      {
        allowWithdraw && !isSelectWithdraw && !isSelectStake && (
          <div className={`${styles.boxCenter}`}>
            <Button
              size="large"
              className={styles.mainButton}
              onClick={startWithdraw}
            >
              Withdraw
            </Button>
          </div>
        )
      }
      <hr className={`${styles.divider} ${styles.spacerTop}`} />

      <h2>Your Unstaked NFTs</h2>

      <div className={`${styles.nftBoxGrid} ${isSelectStake && styles.paddingFloatFooter}`}>
        <Skeleton loading={isFetchNFTs}>
          {nftBalance?.map((nft) => {
            // nft = verifyMetadata(nft);
            return (
              <div
                className={`${styles.nftBox} ${isSelectedStake(nft) && styles.selectedNFTStake}`}
                key={nft.token_id}
                onClick={() => handleSelectNFT(nft.token_address, nft.token_id)}
              >
                {
                  s0uNFTs[getAddress(nft.token_address)] === s0uNFTs.HUMANMUSIC
                    ? <video style={{ width: "100%" }} controls>
                      <source src={convertNFTImage(nft.token_address, nft.token_id, '') || "error"} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    : <Image
                      preview={false}
                      src={convertNFTImage(nft.token_address, nft.token_id, '') || "error"}
                      fallback={s0uImageFallback[nft.token_address]}
                      alt={nft?.metadata?.name || nft?.name}
                      style={{ objectFit: "contain" }}
                    />
                }
                <h4>{nft?.metadata?.name || nft?.name}</h4>
                {
                  isSelectStake && <div className={styles.checkbox}>
                    <Checkbox checked={isSelectedStake(nft)}></Checkbox>
                  </div>
                }
              </div>
            )
          })}
        </Skeleton>
      </div>
      {
        nftBalance && !isSelectStake && !isSelectWithdraw && nftBalance.length > 0 && (
          <div className={`${styles.boxCenter}`}>
            <Button
              size="large"
              className={styles.mainButton}
              onClick={startStake}
            >
              Select for Staking
            </Button>
          </div>
        )
      }
      {
        isSelectStake && (
          <div className={`${styles.footerControls}`}>
            <Button
              size="large"
              className={styles.subButton}
              onClick={cancelStake}
            >
              {'Cancel'}
            </Button>
            <Button
              size="large"
              className={styles.mainButton}
              onClick={startStake}
            >
              Stake
            </Button>
          </div>
        )
      }
      {
        isSelectWithdraw && (
          <div className={`${styles.footerControls}`}>
            <Button
              size="large"
              className={styles.subButton}
              onClick={cancelWithdraw}
            >
              {'Cancel'}
            </Button>
            <Button
              size="large"
              className={styles.mainButton}
              onClick={startWithdraw}
            >
              Withdraw
            </Button>
          </div>
        )
      }
      {
        isLoading && <div className={styles.spinner} >
          <div className={styles.loader}>Loading...</div>
        </div>
      }
    </div>
  );
};

export default HomePage;
