export const ANTHROAddress = process.env.REACT_APP_ANTHRO_CONTRACT;
export const HUMANAddress = process.env.REACT_APP_HUMAN_CONTRACT;
export const HUMANMUSICAddress = process.env.REACT_APP_HUMANMUSIC_CONTRACT;

// export const activeChain = 'eth';
export const activeChain = 'rinkeby';
export const s0uVaults = {
  ANTHRO: 0,
  HUMAN: 1,
  HUMANMUSIC: 2,
  [ANTHROAddress]: 0,
  [HUMANAddress]: 1,
  [HUMANMUSICAddress]: 2
}
export const s0uNFTs = {
  ANTHRO: 'ANTHRO',
  HUMAN: 'HUMAN',
  HUMANMUSIC: 'HUMANMUSIC',
  [ANTHROAddress]: 'ANTHRO',
  [HUMANAddress]: 'HUMAN',
  [HUMANMUSICAddress]: 'HUMANMUSIC'
}

export const s0uImageFallback = {
  ANTHRO: 'https://gateway.ipfs.io/ipfs/Qmez8QrDYPxCHwaoUPEZVzDkz47L6LK47kVwYy3537YvPi/hidden.png',
  HUMAN: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  HUMANMUSIC: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  [ANTHROAddress]: 'https://gateway.ipfs.io/ipfs/Qmez8QrDYPxCHwaoUPEZVzDkz47L6LK47kVwYy3537YvPi/hidden.png',
  [HUMANAddress]: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  [HUMANMUSICAddress]: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
}