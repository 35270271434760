import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Button, Image, Layout } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import HomePage from "components/HomePage";
import { useWallet } from "hooks/useWallet";
import { utils } from 'ethers';

const styles = {
  layout: {
    minHeight: "100vh",
    height: 'fit-content',
    overflow: "auto",
    background: "#000",
    paddingBottom: 40
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Roboto, sans-serif",
    color: "#fff",
    overflow: 'auto',
    background: "url('./images/background.png')",
    backgroundSize: 'cover'
  },
  pageTitle: {
    color: "#fff"
  },
  pageHeader: {
    backgroundImage: "url('./images/header.png')",
    width: '100%',
    backgroundSize: 'cover',
    height: 367,
    backgroundPosition: '50% 50%'
  },
  boxCenter: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: 'center',
    flexDirection: "column",
    flex: '1 0 auto'
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
  accessInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    flexDirection: "column",
    gap: 12
  },
  intro0: {
    backgroundImage: "url('./images/intro-0.png')",
    maxWidth: 980,
    width: '100%',
    height: 'calc(100vw / 2)',
    maxHeight: 552,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  intro1: {
    backgroundImage: "url('./images/intro-1.png')",
    maxWidth: 980,
    width: '100%',
    height: 'calc(100vw / 2)',
    maxHeight: 552,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  intro2: {
    backgroundImage: "url('./images/intro-2.png')",
    maxWidth: 980,
    width: '100%',
    height: 'calc(100vw / 2)',
    maxHeight: 552,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
};

const MAINNET = process.env.REACT_APP_CHAIN_ID;
const { hexValue } = utils;

const App = ({ isServerInfo }) => {
  const { address, network = {}, switchNetwork, connectWallet } = useWallet();
  const chainId = hexValue(network.chainId || 1);
  const queryClient = new QueryClient();

  const isMainnet = chainId === MAINNET;

  useEffect(() => {
    if (address && chainId && !isMainnet) switchNetwork(MAINNET);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network, chainId]);

  useEffect(() => {
    console.log("network: ", network);
  }, [network]);

  const handleSubmit = () => {
    if (address && !isMainnet) {
      switchNetwork(MAINNET);
    } else {
      connectWallet({ signingMessage: "Singularity 0 Universe Staking Authentication" });
    }
  }

  return (
    <Layout style={styles.layout}>
      <QueryClientProvider client={queryClient}>
        <Router>
          {
            address && isMainnet
              ? <div style={styles.content}>
                <Switch>
                  <Route exact path="/">
                    <HomePage isServerInfo={isServerInfo} />
                  </Route>
                  <Route path="/*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </div>
              : <div style={styles.content}>
                <div style={styles.pageHeader}></div>
                <Image src="../public/images/intro-0.png" />
                <div style={styles.intro0}></div>
                <div style={styles.intro1}></div>
                <div style={styles.intro2}></div>
                <div style={styles.boxCenter}>
                  <Button
                    size="large"
                    className="mainButton"
                    onClick={handleSubmit}
                  >
                    {address && !isMainnet ? 'Switch to mainnet' : 'Connect Wallet'}
                  </Button>
                </div>
              </div>
          }
        </Router>
      </QueryClientProvider>
    </Layout>
  );
};

export default App;
