import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

const Application = () => {
  return (
    <App />
  );
};

ReactDOM.render(
  <Application />,
  document.getElementById("root")
);
